import React from "react";

import styled from "styled-components";

const StyledSelect = styled.select`
  font-family: "Roboto", Sans-Serif;
  font-size: 16px;
  color: #2a2a2a;
  border: 1px solid #d8d8d8;
  line-height: 1.375;
  display: block;
  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 4px 28px 4px 8px;
  margin: 16px 24px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAADAAAAAATDPpdAAAArklEQVQoFWNgwAK0tbW1QBiLFAMTuqClpSXn////vUAYxEaXx9Dw6dMnV6AiHhCGslH0oGjQ09NTBJpsBFMBYoPEYHwQzYLMASpQZmJiuo4uBuTfRxajLZsR2XgtLS1nIF8IWQzIfnft2rW9MDEUP7CwsNz78+ePLUwSRAPFFiLzmZE5L1++/CAqKsoPFJOEip+/cuXKCWQ1KMEKkuDn598FpL6AMJQNEsYP8CUNAP13NljNq9o+AAAAAElFTkSuQmCC");
  background-repeat: no-repeat, repeat;
  background-position: right 8px top 50%, 0 0;

  :disabled {
    opacity: 0.8;
  }
`;

/**
 *
 * @param {SelectProps} props
 */
const Select = ({ children, ...props }) => {
  return <StyledSelect {...props}>{children}</StyledSelect>;
};

export default Select;
