import { VictoryArea } from "victory";

const Area = VictoryArea;

Area.defaultProps = {
  ...VictoryArea.defaultProps,
  style: {
    data: { fill: "#3a5dc4" }
  }
};

export default Area;
