import React from "react";
import PropTypes from "prop-types";
import {
  VictoryBar,
  VictoryErrorBar,
  VictoryGroup,
  VictoryStack
} from "victory";

const Progress = props => {
  let valueColor = props.colorScale[0];
  const maxColor = props.colorScale[1];

  const max = props.max === undefined || props.max === null ? 0 : props.max;
  // Would return NaN if applying min to null or undefined
  const value = Math.min(
    props.value === undefined || props.value === null ? 0 : props.value,
    max
  );

  const doesValueExceedMax = props.value < props.max;

  if (!doesValueExceedMax) {
    valueColor = props.colorScale[2];
  }
  return (
    <VictoryGroup
      padding={{ top: 24, right: 1, bottom: 8 }}
      origin={{ x: 0, y: 0 }}
      height={60}
      horizontal
      responsive={false}
    >
      <VictoryGroup>
        <VictoryStack>
          <VictoryBar
            style={{ data: { fill: valueColor } }}
            data={[{ x: 1, y: value }]}
            barWidth={18}
          />
          <VictoryBar
            style={{ data: { fill: maxColor } }}
            data={[{ x: 1, y: max - value }]}
            barWidth={18}
          />
        </VictoryStack>
      </VictoryGroup>
      {doesValueExceedMax && (
        <VictoryErrorBar
          borderWidth={12}
          style={{
            data: {
              stroke: "#191919",
              strokeWidth: 2
            }
          }}
          data={[
            {
              x: 1,
              /*
               * 0.01 is added to the Y value because there seems to be a bug where if errorY === y,
               * the left arm of the error graph does not render.
               */
              y: value + (max - value) / 2 + 0.01,
              errorX: 0,
              errorY: (max - value) / 2
            }
          ]}
        />
      )}
    </VictoryGroup>
  );
};

Progress.propTypes = {
  value(props, propName, componentName) {
    const value = props[propName];
    const max = props.max;

    if (typeof values === "undefined") {
      return;
    }

    if (typeof value !== "number") {
      return new Error(
        `Invalid prop \`${value}\` supplied to \`${componentName}\`. Must be a number.`
      );
    }

    if (value < 0 || max < value) {
      return new Error(
        `Invalid prop \`${value}\` supplied to \`${componentName}\`. Must be between 0 and \`max\` (${max}).`
      );
    }
  },
  max: PropTypes.number
};

Progress.defaultProps = {
  colorScale: ["#1752BC", "#EEF1F9", "#40506E"],
  max: 1
};

export default Progress;
