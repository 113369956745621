import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../Table";
import TableBody from "../TableBody";
import TableCell from "../TableCell";
import TableHead from "../TableHead";
import TableRow from "../TableRow";

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const EnhancedTableHead = ({
  headCells,
  rows,
  classes,
  order,
  orderBy,
  onRequestSort
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow style={{ backgroundColor: "#fff" }}>
        {headCells.map((headCell, cellIndex) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                !headCell.disableSort ? createSortHandler(cellIndex) : () => {}
              }
              hideSortIcon={headCell.disableSort}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function descendingComparator(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => {
        return descendingComparator(
          a.props.children[orderBy].props.children,
          b.props.children[orderBy].props.children
        );
      }
    : (a, b) => {
        return -descendingComparator(
          a.props.children[orderBy].props.children,
          b.props.children[orderBy].props.children
        );
      };
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const TableSortedColumns = ({ headCells = [], rows = [], children }) => {
  const [sortedChildren, setSortedChildren] = useState(children);
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState(0);
  const [order, setOrder] = useState("asc");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useMemo(
    () =>
      setSortedChildren(
        stableSort(sortedChildren, getComparator(order, orderBy))
      ),
    [order, orderBy]
  );

  return (
    <Table>
      <EnhancedTableHead
        headCells={headCells}
        classes={classes}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />

      <TableBody>{sortedChildren}</TableBody>
    </Table>
  );
};

TableSortedColumns.defaultProps = {
  headCells: PropTypes.array,
  rows: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default TableSortedColumns;
