import { VictoryScatter } from "victory";

const Scatter = VictoryScatter;

Scatter.defaultProps = {
  ...Scatter.defaultProps,
  style: { data: { fill: "#3a5dc4" } }
};

export default Scatter;
