import { VictoryPie } from "victory";

const Pie = VictoryPie;

Pie.defaultProps = {
  ...Pie.defaultProps,
  colorScale: ["#000000", "#1d2e62", "#3a5dc4", "#9caee1", "#ffffff"],
  labels: () => null
};

export default Pie;
