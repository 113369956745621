import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles({
  root: {
    cursor: "pointer"
  }
});

export default function StyledTooltip(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const { title, children, placement, rest } = props;

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        className={classes.root}
        PopperProps={{
          disablePortal: true
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableTouchListener
        disableHoverListener
        title={title}
        onClick={handleTooltipOpen}
        placement={placement}
        {...rest}
      >
        <span>{children}</span>
      </Tooltip>
    </ClickAwayListener>
  );
}

StyledTooltip.defaultProps = {
  placement: "bottom",
  title: ""
};

StyledTooltip.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string
};
