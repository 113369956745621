import MuiTableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";

export const TableRow = MuiTableRow;

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F1F2F8"
    }
  }
}))(TableRow);

export default StyledTableRow;
