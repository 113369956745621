import { VictoryBar } from "victory";

const Bar = VictoryBar;

Bar.defaultProps = {
  ...VictoryBar.defaultProps,
  style: {
    data: { fill: "#3a5dc4", width: 17 }
  }
};

export default Bar;
