import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    background: "#fff",
    borerRadius: "2px",
    border: "1px solid #D8D8D8",
    boxShadow: "0 1px 3px 0 rgba(0,0,0,0.1)",
    padding: "1.5rem"
  }
});

const StyledPanel = ({ children, style, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={style} {...rest}>
      {children}
    </div>
  );
};

StyledPanel.defaultProps = {};

StyledPanel.propTypes = {
  style: PropTypes.object
};

export default StyledPanel;
