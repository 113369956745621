import { VictoryLine } from "victory";

const Line = VictoryLine;

Line.defaultProps = {
  ...Line.defaultProps,
  data: [],
  style: {
    data: { stroke: "#3a5dc4" }
  }
};

export default Line;
